/* This injects Tailwind's base styles and any base styles registered by plugins. */
@tailwind base;

/* This injects additional styles into Tailwind's base styles layer. */
@layer base {

    * {
        /* Text rendering */
        text-rendering: optimizeLegibility;
        -o-text-rendering: optimizeLegibility;
        -ms-text-rendering: optimizeLegibility;
        -moz-text-rendering: optimizeLegibility;
        -webkit-text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;

        /* Remove the focus ring */
        &:focus {
            outline: none !important;
        }
    }

    @font-face{
        font-family: greatest richmond;
        src: url("../../../public/assets/fonts/Greatest Richmond.woff");
        font-weight: 400;
        font-style: normal;
        font-display: swap
    }

    @font-face{
        font-family: kitecole;
        src: url(../../../public/assets/fonts/MazzardSoftH-Regular.eot);
        src: url(../../../public/assets/fonts/MazzardSoftH-Regular.eot?#iefix) format("eot"),
             url(../../../public/assets/fonts/MazzardSoftH-Regular.woff2) format("woff2"),
             url(../../../public/assets/fonts/MazzardSoftH-Regular.woff) format("woff"),
             url(../../../public/assets/fonts/MazzardSoftH-Regular.ttf) format("truetype"),
             url(../../../public/assets/fonts/MazzardSoftH-Regular.svg#MazzardSoftH-Regular) format("svg");
        font-weight: 400;
        font-style: normal;
        font-display: swap
    }

    @font-face{
        font-family: kitecole;
        src: url(../../../public/assets/fonts/MazzardSoftH-Bold.eot);
        src: url(../../../public/assets/fonts/MazzardSoftH-Bold.eot?#iefix) format("eot"),
             url(../../../public/assets/fonts/MazzardSoftH-Bold.woff2) format("woff2"),
             url(../../../public/assets/fonts/MazzardSoftH-Bold.woff) format("woff"),
             url(../../../public/assets/fonts/MazzardSoftH-Bold.ttf) format("truetype"),
             url(../../../public/assets/fonts/MazzardSoftH-Bold.svg#MazzardSoftH-Bold) format("svg");
        font-weight: 700;
        font-style: normal;
        font-display: swap
    }

    /* HTML and Body default styles */
    html,
    body {
        font-family: "kitecole", helveticaneue-light, "helvetica neue light", "helvetica neue", Helvetica, Arial, "lucida grande", sans-serif;
        // display: flex;
        // flex-direction: column;
        // flex: 1 1 auto;
        // width: 100%;
        // min-height: 100vh;
        // min-height: 100%;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }

    /* Font size */
    html {
        font-size: 16px;
    }

    // body {
    //     font-size: 0.875rem;
    //     height: 100vh;
    // }

    /* Stylistic alternates for Inter */
    body {
        font-feature-settings: 'salt';
        font-size: 0.875rem;
        height: 100vh;
    }

    /* Better spacing and border for horizontal rule */
    hr {
        margin: 32px 0;
        border-bottom-width: 1px;
    }

    /* Make images and videos to take up all the available space */
    // img {
    //     width: 100%;
    //     vertical-align: top;
    // }

    /* Fix: Disabled placeholder color is too faded on Safari */
    input[disabled] {
        opacity: 1;
        -webkit-text-fill-color: currentColor;
    }

    textarea,
    input[type] {
        font-size: 14px;
    }

    /* Button*/
    button {
        background-color: theme('colors.secondary');
    }

    // body {
    //     @apply text-default bg-default #{'!important'};
    // }

    *, *::before, *::after {
        --tw-border-opacity: 1 !important;
        border-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity));

        .dark & {
            --tw-border-opacity: 0.12 !important;
        }
    }

    // [disabled] * {
    //     @apply text-disabled #{'!important'};
    // }

    /* Print styles */
    @media print {

        /* Make the base font size smaller for print so everything is scaled nicely */
        html {
            font-size: 12px !important;
        }

        body, .dark, .light {
            background: none !important;
        }
    }
}

/* This injects Tailwind's component classes and any component classes registered by plugins. */
@tailwind components;
@tailwind utilities;