@import './tailwind.scss';

#root {
  height: 100%;
}

// .grid-container {
//   display: grid;
//   grid-template-areas: 'header' 'main' 'footer';
//   grid-template-columns: 1fr;
//   grid-template-rows: 5rem 1fr 5rem;
//   height: 100%;
// }

header {
  // grid-area: header;
  // background-color: #0084b5;
}

.header-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.footer-sticky {
  position: fixed;
  bottom: 0;
  z-index: 20;
  width: 100%;
}

.footer-search {
  z-index: -10;
}

main {
  // grid-area: main;
  min-height: 760px;
  padding: 2rem;
}

footer {
  // grid-area: footer;
  // background-color: #0b85b8;
  // color: #ffffff;
  margin-top: 2rem;
}



// h1 {
//   font-size: 1.8rem;
//   padding: 1rem 0;
// }

// h2 {
//   font-size: 1.6rem;
//   padding: 1rem 0;
// }

// a {
//   text-decoration: none;
// }

a:hover{
  color: theme('colors.secondary');
}

a.brand {
  /* color: #404040; */
  font-size: 2rem;
  font-weight: bold;
}

.badge {
  background-color: #f02020;
  color: #ffffff;
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.row.top {
  align-items: flex-start;
}

.row.center {
  justify-content: center;
}

.col-1 {
  flex: 1 1 25rem;
}

.col-2 {
  flex: 2 1 50rem;
}

.min-30 {
  min-width: 25rem;
}

.p-1 {
  padding: 1rem;
}
/* Aside */
aside {
  position: fixed;
  width: 30rem;
  height: 100%;
  background-color: #efefef;
  z-index: 1000;
  transform: translateX(-30rem);
  transition: all 0.5s;
}
aside.open {
  transform: translateX(0);
}
button.open-sidebar {
  font-size: 3rem;
  padding: 0.2rem 0.5rem;
  margin: 0 0.5rem;
  background: none;
  color: #BCC0C9;
  cursor: pointer;
}
button.open-sidebar:hover {
  border-color: #ffffff;
}
aside ul {
  padding: 0;
  list-style: none;
}
aside li {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
button.close-sidebar {
  padding: 0.3rem 0.8rem;
}

/*image*/
// img {
//   border-radius: 0.5rem;
// }
img.small {
  max-width: 6.5rem;
  width: 100%;
}
img.medium {
  width: 100%;
}

/*card*/
.card {
  max-width: 25rem;
  /* border: 0.1rem #c0c0c0 solid; */
  background-color: #ffffff;
  border-radius: 0.5rem;
  margin: 1rem;
}

.card-body {
  padding: 1rem;
}

.card-body > * {
  margin-bottom: 0.5rem;
}

.price {
  font-size: 2rem;
}

/* Title */
.product-title {
  height: 7rem;
}

.product-title a{
  color: #0084b5;
}

/* Rating */
.rating {
  color: #f0c040;
}

.rating span:last-child {
  color: #404040;
}

/*Alert*/
.success {
  color: #20a020
}

.danger {
  color: #a02020;
}

.alert {
  padding: 1rem;
  border: 0.1rem transparent solid;
  border-radius: 2px;
}

.alert-info {
  color: #ffffff;
  background-color: #3d9cd2;
}

.alert-danger {
  // color: #a02020;
  color: #ffffff;
  background-color: #9e0505;
}

.alert-success {
  // color: #a02020;
  color: #ffffff;
  background-color: #20a020;
}

.loading {
  display: block !important;
}

/* Form */

.form {
  max-width: 60rem;
  margin: 0 auto;
}

.form > div {
  display: flex;
  margin: 1rem;
}

.form label {
  margin: 1rem 0;
  display: block;
}

/* Dropdown */

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 12rem;
  padding: 1rem;
  z-index: 1;
  background-color: #ffffff;
  margin: 0;
  margin-top: 0.4rem;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a{
  color: #404040;
}

/* Checkout Step */

.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
}

.checkout-steps > div.active {
  border-top: 0.3rem #ea8c1c solid;
  color: #ea8c1c;
}

/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
}
.table tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}
.table td,
.table th {
  text-align: left;
  border: 0.1rem solid #e4e4e4;
  padding: 0.5rem;
}
.table button {
  margin: 0 0.2rem;
}
/* Carousel */
// .carousel .slide img {
//   max-width: 30rem;
// }
.carousel.carousel-slider {
  height: 100%;
}

.carousel > .slider-wrapper, .slider {
  height: 100%;
}

.carousel .control-dots .dot{
  height: 12px !important;
  width: 12px !important;
}

ul.slider > li.slide.selected {
  z-index: 0 !important;
}

.home-slider img{
  object-fit: cover;
}

.greatest-richmond-font {
  font-family: "Greatest Richmond";
}

.horizontal-flip {
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

/* Multiple carousel */
.react-multi-carousel-dot-list{
  margin-top: 15px !important;
}

.home-multi-carousel-action {
  transform: translate(0, 20px);
  animation: initial-action 1s;
}

@keyframes initial-action {
  0% {
    translate: 0px 0px;
  }
}

.home-multi-carousel-image:hover {
  .home-multi-carousel-action {
    visibility: visible;
    transform: translate(0, 0px);
    animation: action 500ms;
  }
  img{
    transform: scale(1.05);
    transition-duration: 500ms;
  }

  @keyframes action {
    0% {
      translate: 0px 15px;
    }
  }
  
  .price-border{
    width: 100px;
    transition-duration: 500ms;
    // animation: price 5s;
  }

  @keyframes price {
    0% {
      translate: 0px 0px 10px 10px;
    }
  }
}

.home-multi-carousel-image button:hover {
  .chevron{
    margin-left: 5px;
    transition-duration: 500ms;
  }
}

/* Special products*/
.special-product {
  background-image: url('../../../public/assets/images/backgrounds/special-product-bg-1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: center;
  min-height: 722px;
}

.special-product {
  .carousel .slider-wrapper{
    border-radius: 10px;
  }
  .carousel .control-next.control-arrow:before {
    border-left: none !important;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: none !important;
  }
  
  .carousel.carousel-slider {
    overflow: visible !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: block !important;
    opacity: 1 !important;
    width: 50px;
    height: 50px;
    color: #000000;
    top: 45% !important;
    padding: 0;
    border-radius: 50%;
    // border: 1px solid #9CA3AF !important;
    border-color: #ebebeb;
    background-color: #fff !important;
    outline: none;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08);
  }

  .carousel.carousel-slider .control-arrow:hover {
    background-color: theme('colors.secondary') !important;
    border: 1px solid theme('colors.secondary') !important;
    color: #ffffff;
  }

  .our-offers-title {
    font-style: "greatest richmond";
  }
  
  .control-next::before {
    content: "→" !important;
  }
  
  @media only screen and (min-width: 1124px) {
    .control-next {
      right: -100px !important;
    }

    .control-prev {
      left: -100px !important;
    }
  }

  @media only screen and (max-width: 1123px) {
    .control-next {
      right: -25px !important;
    }

    .control-prev {
      left: -25px !important;
    }
  }

  .control-prev::before {
    content: "←" !important;
  }

  .special-product-add-cart:hover {
    p {
      margin-left: 10px;
      transition: 500ms;
    }
  }
}

/* Product Details */

.product-details-section {
  p{
    margin-top: -2px;
    width: 0;
    height: 2px;
    transition-duration: 500ms;
  }

  #details:hover {
    button {
      color: theme('colors.secondary');
    }

    p{
      width: 100%;
      transition-duration: 500ms;
    }
  }

  #description:hover {
    button {
      color: theme('colors.secondary');
    }

    p{
      width: 100%;
      transition-duration: 500ms;
    }
  }

  #avis:hover {
    button {
      color: theme('colors.secondary');
    }
    
    p{
      width: 100%;
      transition-duration: 500ms;
    }
  }
}
/* Search */
.search-box:hover {
    // button,
    // input {
    //   border-color: theme('colors.ternary');
    // }

    // button {
    //   background-color: theme('colors.ternary');
    // }
}

// .search button {
//   border-radius: 0 5rem 5rem 0;
//   border-right: none;
//   margin-right: 0.5rem;
// }
// .search input {
//   border-radius: 5rem 0 0 5rem;
//   border-right: none;
//   margin-left: 0.5rem;
// }
.active {
  color: theme('colors.secondary');
  font-weight: bold;
}

/* Map */
.full-container {
  margin: -1rem;
  height: calc(100% + 2rem);
}
.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  width: 25rem;
  height: 4rem;
  display: flex;
}
.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}
.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}
/* Pagination */
.pagination a {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pagination a.active {
  font-weight: bold;
}

/* Dashboard */

.summary > li {
  border: 0.1rem #c0c0c0 solid;
  margin: 2rem;
  border-radius: 0.5rem;
  flex: 1 1 20rem;
}
.summary-title {
  font-size: 2rem;
  padding: 1rem;
}
.summary-body {
  font-size: 4rem;
  padding: 1rem;
  text-align: center;
}

.summary-title.color1 {
  background-color: #f0e0e0;
}
.summary-title.color2 {
  background-color: #e0f0e0;
}
.summary-title.color3 {
  background-color: #e0e0f0;
}

/* Chatbox */
.chatbox {
  color: #000000;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}
.chatbox ul {
  overflow: scroll;
  max-height: 20rem;
}
.chatbox li {
  margin-bottom: 1rem;
}
.chatbox input {
  width: calc(100% - 9rem);
}

.support-users {
  background: #f0f0f0;
  height: 100%;
}
.support-users li {
  background-color: #f8f8f8;
}
.support-users button {
  background-color: transparent;
  border: none;
  text-align: left;
}
.support-users li {
  margin: 0;
  background-color: #f0f0f0;
  border-bottom: 0.1rem #c0c0c0 solid;
}

.support-users li:hover {
  background-color: #f0f0f0;
}
.support-users li.selected {
  background-color: #c0c0c0;
}
.support-messages {
  padding: 1rem;
}
.support-messages input {
  width: calc(100% - 9rem);
}
.support-messages ul {
  height: calc(100vh - 18rem);
  max-height: calc(100vh - 18rem);
  overflow: scroll;
}
.support-messages li {
  margin-bottom: 1rem;
}

.support-users span {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: absolute;
  margin-left: -25px;
  margin-top: 10px;
}
.support-users .offline {
  background-color: #808080;
}
.support-users .online {
  background-color: #20a020;
}
.support-users .unread {
  background-color: #f02020;
}

/* Banner */

.banner-height {
  height: 300px;
}

/* Tooltip */
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #000000;
  color: #ffffff;
  font-size: 12px;
  position: absolute;
  padding: 2px 5px 2px 5px;
  top: 100%;
  // left: auto;
  right: 100%;
  white-space: nowrap;
  max-width: 400px;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

.line-clamp-1	{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.line-clamp-3	{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-4	{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.line-clamp-6	{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.line-clamp-none	{
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none;
}