.multi-range-slider {
    display: flex;
    position: relative;
    border: none !important;
    /* border-radius: 10px; */
    padding: 0 !important;
    box-shadow: none !important;
    flex-direction: column;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.multi-range-slider .bar-left {
    width: 25%;
    background-color: #E5E5E5 !important;
    border-radius: 10px 0px 0px 10px;
    box-shadow: none !important;
    padding: 2px 0px !important;
}

.multi-range-slider .bar-inner {
    background-color: lime;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
    border: none !important;
    justify-content: space-between;
    box-shadow: none !important;
}

.multi-range-slider .bar-right {
    width: 25%;
    background-color: #E5E5E5 !important;
    border-radius: 0px 10px 10px 0px;
    box-shadow: none !important;
}

.multi-range-slider .thumb {
    background-color: white;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.multi-range-slider .thumb::before {
    content: '';
    background-color: inherit;
    position: absolute;
    width: 15px !important;
    height: 15px !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 50%;
    z-index: 1;
    margin: -6px -12px !important;
    cursor: ew-resize !important;
}

.multi-range-slider .thumb .caption * {
    display: none;
    position: absolute;
    min-width: 30px;
    height: 30px;
    font-size: 75%;
    text-align: center;
    line-height: 30px;
    background-color: theme('colors.primary') !important;
    border-radius: 15px;
    color: white;
    box-shadow: none !important;
    padding: 0px 5px;
    white-space: nowrap;
}